<template>
  <ul class="social-box">
    <li class="social-icon">
      <span class="icon svg-facebook" role="img" aria-label="facebook"></span>
    </li>
    <li class="social-icon">
      <span class="icon svg-instagram" role="img" aria-label="instagram"></span>
    </li>
    <li class="social-icon">
      <span class="icon svg-twitter" role="img" aria-label="twitter"></span>
    </li>
    <li class="social-icon">
      <span class="icon svg-linkedin" role="img" aria-label="linkedin"></span>
    </li>
  </ul>
</template>

<style></style>

<script>
export default {
  name: 'BlockSocialIcons',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
};
</script>
